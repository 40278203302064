<template>
  <div>
    <div style="margin-bottom: 10px">
      <img
        style="width: 15px"
        src="../../assets/images/goback.png"
        alt=""
        @click="toBack"
      />
      <span style="margin-right: 15px" @click="toBack"
        >&nbsp;检查列表：{{ $route.query.year }}/{{
          $route.query.companyName
        }}</span
      >
    </div>
    <div class="_Tab">
      <span
        v-for="(item, index) in ['待审核', '已审核']"
        :key="index"
        :class="{ activeColor: index == tabIndex }"
        @click="toChange(index)"
      >
        {{ item }}
      </span>
    </div>
    <div class="mid_box" v-if="isCheck">
      <el-button type="primary" class="edit" @click="toOpen"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <el-button type="primary" class="edit" @click="addQuestion"
        >新增提问<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <span style="margin-top: 5px; margin-left: 10px">
        <img
          src="../../assets/images/Examine_img/del1.png"
          alt=""
          @click="delItem"
        />
      </span>
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="getPdf()"
        >打印检查表</el-button
      >
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="exportExcel2()"
        >导出检查表</el-button
      >
    </div>
    <div class="toSave1" @click="toSave" v-if="!isCheck">
      <img
        style="width: 15px"
        src="../../assets/images/Examine_img/sava.png"
        alt=""
      />
      <span>完成检查</span>
    </div>
    <div class="_TableBox">
      <el-table
        ref="multipleTable"
        v-if="isCheck"
        :data="tableData1.slice(start, end)"
        tooltip-effect="dark"
        style="width: 100%"
        :key="itemKey"
        :height="elementHeight"
        highlight-current-row
        border
        @select="selectRow"
        @select-all="selectAll"
        @row-click="handleRowClick"
        @cell-dblclick="handleCell"
        @selection-change="handleSelectionChange"
        :stripe="true"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="question" label="问题" width="480">
        </el-table-column>
        <el-table-column
          prop="auditResult"
          label="是否符合要求  "
          align="center"
          width="250"
          v-if="!this.isshowAns"
        >
          <template slot-scope="scope">
            <div class="auditResult_box">
              <div
                @click="changeResult(scope.row, 1, '符合')"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >
                符合
              </div>
              <div
                @click="changeResult(scope.row, 0, '不符合')"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >
                不符合
              </div>
              <div
                @click="changeResult(scope.row, 2, '纠正')"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >
                纠正
              </div>
              <div
                @click="changeResult(scope.row, 3, '改进')"
                :class="{ auditactive: scope.row.auditResult === 3 }"
              >
                改进
              </div>
              <div
                @click="changeResult(scope.row, 4, '不适用')"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >
                不适用
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="答案"
          v-if="this.isshowAns"
          prop="answer"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="查看答案" v-else show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="button"
              plain
              @click="isChoose(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-else
        ref="multipleTable"
        :data="tableData2.slice(start, end)"
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        :key="itemKey"
        border
        highlight-current-row
        id="multipleTable"
        @select="selectRow"
        @select-all="selectAll"
        @row-click="handleRowClick"
        @cell-dblclick="handleCell"
        @selection-change="handleSelectionChange"
        :stripe="true"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="question" label="问题"> </el-table-column>
        <el-table-column
          prop="auditResult"
          label="是否符合要求"
          align="center"
          width="300"
          v-if="!this.isshowAns"
        >
          <template slot-scope="scope">
            <div class="auditResult_box">
              <div
                @click="changeResult(scope.row, 1, '符合')"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >
                符合
              </div>
              <div
                @click="changeResult(scope.row, 0, '不符合')"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >
                不符合
              </div>
              <div
                @click="changeResult(scope.row, 2, '纠正')"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >
                纠正
              </div>
              <div
                @click="changeResult(scope.row, 3, '改进')"
                :class="{ auditactive: scope.row.auditResult === 3 }"
              >
                改进
              </div>
              <div
                @click="changeResult(scope.row, 4, '不适用')"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >
                不适用
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="imagesCount"
          label="照片列表"
          align="center"
          width="190
        "
          v-if="!this.isshowAns"
        >
          <template slot-scope="scope">
            <div class="img_box" @click="openImg(scope.row)">
              <img src="../../assets/images/Examine_img/add.png" alt="" />
              <span v-show="scope.row.imagesCount === 0">请添加图片</span>
              <span
                v-show="scope.row.imagesCount > 0"
                style="margin-right: 50%"
                >{{ scope.row.imagesCount }}</span
              >
              <img src="../../assets/images/Examine_img/picture.png" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="lawAsk"
          v-if="!this.isshowAns"
          label="审核意见（双击列表）"
          width="250"
        >
          <template slot-scope="scope">
            <input
              v-if="
                AreaAuditId === scope.row.id && columnID === scope.column.id && ($route.query.status != 2)
              "
              type="text"
              @focus="compare(scope.row.lawAsk)"
              @blur="Updata(scope.row, scope.row.lawAsk)"
              v-model="scope.row.lawAsk"
            />
            <span v-else>{{ scope.row.lawAsk }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="答案"
          v-if="this.isshowAns"
          prop="answer"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.answer }}</template>
        </el-table-column>
        <el-table-column
          label="查看答案"
          v-else
          prop="answer"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="button"
              plain
              @click="isChoose(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="isCheck ? tableData1.length : tableData2.length"
        class="_Pagination"
      >
      </el-pagination>
    </div>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="company"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属部门:</span>
        <el-select
          v-model="deptID"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属岗位:</span>
        <el-select
          v-model="postID"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in option3"
            :key="item.value"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目:</span>
          <div class="topic">
            <div>涉及方面</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img
              src="../../assets/images/Examine_img/top.png"
              @click="isshow = !isshow"
              alt=""
            />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div
              style="display: flex"
              v-for="(item, index) in titlelist"
              :key="index"
            >
              <span style="margin: 10px"
                >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
              <div class="topic1">
                <div>{{ item.functionName }}</div>
                <div class="Centernumbet">
                  <span>{{ item.titleCount }}</span>
                </div>
                <div id="number">
                  <img
                    @click="sub(item)"
                    src="../../assets/images/Examine_img/slices/del1.png"
                    alt=""
                  />
                  <input
                    type="text"
                    style="ime-mode: disabled"
                    @keyup="test(item)"
                    v-model="item.extractCount"
                  />
                  <img
                    @click="plus(item)"
                    src="../../assets/images/Examine_img/slices/plus1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toReAdd">确定抽题</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增提问" :visible.sync="dialogVisible1" width="38%">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin-right: 10px; width: 60px">问题描述</div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="PostData.question"
            maxlength="500"
            show-word-limit
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddASK">确定提问</el-button>
        <el-button @click="dialogVisible1 = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看/添加图片"
      :visible.sync="dialogVisibleimg"
      width="40%"
    >
      <div v-show="status != 2">上传图片</div>
      <!-- :action="this.BUrl + '/api/UpLoadFiles/UploadImg'" -->
      <!-- :on-success="getaddr" -->
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
        v-show="status != 2"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>

        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex && status != 2"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <!-- <el-button @click="dialogVisibleimg = false">取 消</el-button> -->
      </span>
    </el-dialog>
    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div style="font-size: 18px">答案:{{ answer }}</div>
      <div style="font-size: 18px">图片:</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleAsk = false">确 定</el-button>
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核结果" :visible.sync="dialogVisibleresult" width="35%">
      <el-form label-width="80px">
        <el-form-item label="审核结果:">
          <span>{{ state }}</span>
        </el-form-item>
        <el-form-item label="审核意见:">
          <el-input v-model="Adoption" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <div class="_Select" style="margin-bottom: 20px">
          <span>隐患类别：</span>
          <el-select v-model="remark" clearable placeholder="请选择">
            <el-option
              v-for="item in ['一般', '不可接受', '重复']"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <el-form-item label="上传照片:">
          <el-upload
            action=" https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已上传图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
            <el-image
              style="width: 100px; height: 100px; margin-right: 3px"
              :src="item"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            >
            </el-image>
            <i
              class="el-icon-delete-solid"
              v-show="index === Curindex"
              @click="delImg(item)"
            ></i>
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="toSure">确认</el-button>
      </span>
    </el-dialog>
    <el-table
      id="pdfDom"
      :data="tableData"
      style="width: 100%; position: absolute; left: -100%; z-index: -999;"
    >
      <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="fileName" label="文件名称"> </el-table-column>
      <el-table-column prop="question" label="问题"> </el-table-column>
      <el-table-column
        prop="auditResult"
        label="是否符合要求  "
        v-if="!this.isshowAns"
        align="center"
        width="250"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 0, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >
              不符合
            </div>
            <div
              @click="changeResult(scope.row, 2, '纠正')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              纠正
            </div>
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >
              改进
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        align="center"
        width="190"
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt="" />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span
              v-show="scope.row.imagesCount > 0"
              style="margin-right: 50%"
              >{{ scope.row.imagesCount }}</span
            >
            <img src="../../assets/images/Examine_img/picture.png" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="answer"
        v-if="this.isshowAns"
        label="答案"
      >
      </el-table-column>
      <el-table-column prop="answer" v-else label="答案">
      </el-table-column>
      <el-table-column prop="answer" label="审核意见（双击列表）">
        <template slot-scope="scope">
          <span>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { deleteMethod, get, post, put, baseURL } from "../../api/http";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { compressImage } from "../../modules/CompressImage";
export default {
  data() {
    return {
      token: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      htmlTitle: "文件检查",
      elementHeight: 0,
      BUrl: baseURL[process.env.NODE_ENV + ""],
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleimg: false,
      dialogVisiblebig: false,
      dialogVisibleAsk: false,
      dialogVisibleresult: false,
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3:[],
      state: "",
      Adoption: "",
      Cid: null,
      isCheck: true,
      itemKey: "",
      textarea: "",
      titlelist: null,
      imgList: [],
      srcList: [],
      curRow: "",
      curNum: "",
      value: "",
      currentPage: 0,
      company: 0,
      deptID: 0,
      postID: 0,
      number: "",
      url: "",
      imgSrc: "",
      showImg: false,
      options: [],
      Curindex: "",
      AreaAuditId: "",
      columnID: "",
      option2: [],
      option3: [],
      curlawask: "",
      isSave: true,
      start: 0,
      end: 10,
      delQuestion: null,
      isshowAns: false,
      currentPage1: 0,
      curSize: 10,
      option1: [],
      answer: "",
      PostData: {
        fileAuditId: 0,
        questionId: 0,
        question: "",
        answer: "",
        lawAsk: "",
        imagesCount: 0,
      },
      remark: "",
      tabIndex: 0,
      status:this.$route.query.status,
    };
  },
  activated() {
    if (
      sessionStorage.getItem("FId") != this.$route.query.Id ||
      this.tableData.length == 0
    ) {
      this.company = Number(this.$route.query.CpyID);
      this.deptID = Number(this.$route.query.deptID);
      this.postID = Number(this.$route.query.postId);
      this.PostData.fileAuditId = this.$route.query.Id;
      this.getTdata();
      this.getElementHeight();
    }
    this.isCheck = true;
    this.tabIndex = 0;
  },
  methods: {
    addQuestion(){
      this.dialogVisible1 = true;
      this.PostData.question = '';
    },
    async getCompany() {
      await get("/api/Company/GetAll").then((res) => {
        this.options = res.data;
      });
      await get("/api/Department/GetNoTree?CompId=" + this.company).then(
        (res) => {
          this.option1 = res.data;
        }
      );
    },
    async getPost() {
      await get("/api/Post?CompId=" + this.company + "&DeptId=" + this.deptID)
        .then((res) => {
          this.option3 = res.data;
        })
        .then(() => {
          get(
            "/api/NewFileAudit/GetTitleList?CompId=" +
              this.company +
              "&DeptId=" +
              this.deptID +
              "&PostId=" +
              this.postID +
              "&PlanId=" +
              this.$route.query.planId +
              "&FileAuditId=" +
              this.$route.query.Id
          )
            .then((res) => {
              if (res.code == 200) {
                this.titlelist = res.data;
                this.dialogVisible = true;
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(() => {
              this.$message.error("抽题失败");
            });
        });
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 10 + 20 + 64 + 40 + 70); //70是div底部距离底部的高度
      });
    },
    getTdata() {
      get(
        "/api/FileAuditList/GetByFileAuditId?FileAuditId=" +
          this.$route.query.Id
      ).then((res) => {
        this.tableData = res.data;
        this.tableData1 = this.tableData.filter((item) => {
          return item.auditResult === null;
        });
        this.tableData2 = this.tableData.filter((item) => {
          return item.auditResult !== null;
        });
      });
    },
    toOpen() {
      if (this.tableData2.length != 0) {
        this.$message({
          type: "warning",
          message: "已有题目审核过,不可重新抽题",
        });
        return;
      }
      this.getCompany();
      this.getPost();
      for (let index = 0; index < this.tableData.length; index++) {
        if (this.tableData[index].auditResult !== null) {
          return;
        }
      }
    },
    toSave() {
      if(this.tableData2.length == 0){
        this.$message({
          type: "warning",
          message: "无审核数据，请先审核数据",
        });
        return;
      }
      this.isSave = true;
      this.tableData.forEach((e) => {
        if (e.auditResult === null) this.isSave = false;
      });
      if (this.isSave) {
        var item = JSON.parse(sessionStorage.getItem("Fitem"));
        if (item.status === 2) {
          this.$message({
            type: "success",
            message: "已经审核完成，请勿重复完成",
          });
          return;
        }
        put("/api/FileAudit", item).then((res) => {
          console.log(res);
          this.$message({
            type: "success",
            message: res.message,
          });
          this.isSave = true;
          item.status = 2;
          this.status = 2;
          sessionStorage.setItem("Fitem", JSON.stringify(item));
        });
      } else {
        this.$message({
          type: "info",
          message: "未审核完成",
        });
      }
    },
    toBack() {
      this.$router.go(-1);
      sessionStorage.setItem("FId", this.$route.query.Id);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++;
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--;
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = "";
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount;
    },
    overimg(index) {
      this.Curindex = index;
    },
    openImg(e) {
      this.Cid = e.id;
      this.dialogVisibleimg = true;
      this.getImage();
    },
    selectRow(e, v) {
      this.delQuestion = e;
      this.$refs.multipleTable.setCurrentRow(v);
      this.AreaAuditId = v.id;
      //this.handleRowClick(v);
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          console.log(e.id);
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod("/api/FileAuditListImage?Id=" + e.id).then(() => {
                this.getImage();
              });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    changeResult(row, num, state) {
      if(this.status == 2){
        this.$message({
          type: "warning",
          message: '已完成检查，禁止修改',
        });
        return;
      }
      this.Adoption = row.lawAsk;
      this.remark = row.remark;
      console.log(row);
      if (num >= 0) {
        this.state = state;
        this.Cid = row.id;
        this.curRow = row;
        this.curNum = num;
        this.dialogVisibleresult = true;
        this.getImage();
        return;
      }
      row.auditResult = num;
      put("/api/FileAuditList", row).then((res) => {
        if (res.code != "200") {
          this.$message({
            type: "error",
            message: res.message,
          });
          row.auditResult = null;
          return;
        }
        this.$message({
          type: "success",
          message: res.code == 200 ? "保存成功" : res.message,
        });
        for (var i = 0; i < this.tableData1.length; i++) {
          if (this.tableData1[i].id === row.id) {
            this.tableData1.splice(i, 1);
          }
        }
        for (var k = 0; k < this.tableData2.length; k++) {
          if (this.tableData2[k].id === row.id) {
            return;
          }
        }
        this.tableData2.push(row);
      });
    },
    toSure() {
      this.curRow.auditResult = this.curNum;
      this.curRow.lawAsk = this.Adoption;
      this.curRow.remark = this.remark;
      put("/api/FileAuditList", this.curRow).then((res) => {
        this.$message({
          type: "success",
          message: res.code == 200 ? "保存成功" : res.message,
        });
        if (res.code == 200) {
          for (var i = 0; i < this.tableData1.length; i++) {
            if (this.tableData1[i].id === this.curRow.id) {
              this.tableData1.splice(i, 1);
            }
          }
          for (var k = 0; k < this.tableData2.length; k++) {
            if (this.tableData2[k].id === this.curRow.id) {
              return;
            }
          }
          this.tableData2.push(this.curRow);
        }
        //this.$refs.upload.clearFiles();
        this.$refs.upload.clearFiles();
      });
      this.dialogVisibleresult = false;
      this.srcList = [];
      this.Adoption = "";
      this.remark = "";
    },
    selectAll(e) {
      this.delQuestion = e;
    },
    toChange(i) {
      this.tabIndex = i;
      this.isCheck = this.tabIndex == 0? true:false;
      this.start = 0;
      this.end = 10;
      this.currentPage1 = 1;
    },
    compare(e) {
      this.curlawask = e;
      console.log(this.curlawask);
    },
    Updata(row, e) {
      console.log(row);
      this.columnID = "";
      if (this.curlawask === e) return;
      put("/api/FileAuditList", row).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message:"更改成功",
          });
        }else{
          this.$message.error(res.message);
          row.lawAsk = '';
        }
        //this.getTdata()
      });
    },
    toReAdd() {
      if (this.titlelist === null) {
        this.$message({
          type: "success",
          message: "未选择题目",
        });
        return;
      }
      var url1 = "/api/NewFileAudit/InsertExtract";
      var url2 = "/api/NewFileAudit/AgainExtract";
      var url =
        this.tableData1.length == 0 && this.tableData2.length == 0
          ? url1
          : url2;
      post(
        url +
          "?FileAuditId=" +
          this.$route.query.Id +
          "&CompId=" +
          this.company +
          "&DeptId=" +
          this.deptID +
          "&PostId=" +
          this.postID,
        {
          data: JSON.stringify(this.titlelist),
        }
      ).then((res) => {
        if (res.code == "400") {
          this.$message({
            type: "success",
            message: res.message,
          });
          return;
        }
        this.getTdata();
        this.$message({
          type: "success",
          message: res.code == 200 ? "抽题成功" : res.message,
        });
        this.dialogVisible = false;
      });
    },
    exportExcel2() {
      this.start = 0;
      this.end = this.tableData.length;
      // this.tableData1 = this.tableData;
      // this.tableData2 = this.tableData;
      // this.tableData3 = this.tableData1; 待审核
      this.tableData3 = this.tableData;
      this.isshowAns = true;
      this.$nextTick(() => {
        var wb = XLSX.utils.table_to_book(document.querySelector("#pdfDom"));
        var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        try {
          FileSaver.saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "文件检查.xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        this.start = 0;
        this.end = 10;
        this.isshowAns = false;
        this.getTdata();
        return wbout;
      });
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage1 - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleClose() {},
    handlePictureCardPreview() {
      this.dialogVisiblebig = true;
    },
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            // 压缩后比原来更大，则将原图上传
            //调接口上传图片到服务器
            this.uploadFileReq(e.file);
          } else {
            //result压缩结果
            // 压缩后比原来小，上传压缩后的
            //调接口上传图片到服务器
            this.uploadFileReq(result);
          }
        });
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadImg", formData)
        .then((res) => {
          this.$refs.upload.clearFiles();
          if (res.code == 200) {
            var data = {
              fileAuditListtId: this.Cid,
              ImageRemark: "",
              ImageAddress: this.BUrl + "/uploads/" + res.value,
              saveUserId: this.$store.state.userInfo.id,
            };
            post("/api/FileAuditListImage", {
              data: JSON.stringify([data]),
            }).then(() => {
              this.$refs.upload.clearFiles();
              this.getImage();
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    // getaddr(res) {
    //   var data = {
    //     fileAuditListtId: this.Cid,
    //     ImageRemark: "",
    //     ImageAddress: this.BUrl + "/uploads/" + res.value,
    //     saveUserId: this.$store.state.userInfo.id,
    //   };
    //   console.log(data);
    //   post("/api/FileAuditListImage", { data: JSON.stringify([data]) }).then(
    //     (res) => {
    //       console.log(res);
    //       get("/api/FileAuditListImage/" + this.Cid).then((res) => {
    //         this.srcList = [];
    //         this.imgList = res.value;
    //         res.value.forEach((element) => {
    //           this.srcList.push(element.picNameAndAddr);
    //         });
    //       });
    //     }
    //   );
    // },
    getImage() {
      this.srcList = [];
      get("/api/FileAuditListImage/" + this.Cid).then((res) => {
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
      });
    },
    toAddASK() {
      this.dialogVisible1 = false;
      post("/api/FileAuditList", this.PostData).then((res) => {
        if(res.code==200){
          this.getTdata();
        }else{
          this.$message.error(res.message);
        }
      });
    },
    toPostImg() {
      this.dialogVisibleimg = false;
      this.$refs.upload.clearFiles();
      this.getTdata();
      //this.$refs.upload.clearFiles();
    },
    handleCell(row, column) {
      this.columnID = column.id;
    },
    togetArea(e) {
      get("/api/Area/GetByCompId?CompId=" + e).then((res) => {
        this.option2 = res.data;
      });
    },
    delItem() {
      if (this.delQuestion.length == 0) {
        this.$message({
          type: "info",
          message: "未选择题目",
        });
        return;
      }
      var idList = [];
      this.delQuestion.forEach((element) => {
        idList.push({ id: element.id });
      });
      this.$confirm(
        "此操作将永久删除所有打勾的题目" + this.delQuestion.length + "道",
        "是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          post("/api/FileAuditList/BlatchDelete", {
            data: JSON.stringify(idList),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.getTdata();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    handleSelectionChange(value) {
      this.handleSelectionList = value;
    },

    handleRowClick(row) {
      this.AreaAuditId = row.id;

      //this.selectChange(this.handleSelectionList);
    },
    isChoose(e) {
      this.srcList = [];
      this.answer = e.answer;
      this.dialogVisibleAsk = true;
      if (e.questionImageAddr) {
        var imgdata = e.questionImageAddr.split(",");
        console.log(imgdata);
        imgdata.forEach((e) => {
          this.srcList.push(this.BUrl + "/uploads/" + e);
        });
      }
      // this.answer = e.answer;
      // this.dialogVisibleAsk = true;
      // this.srcList = [];
      //   if(e.questionImageAddr){
      //     this.srcList.push(this.BUrl + "/uploads/" + e.questionImageAddr);
      //   }
    },
    changeRemark() {},
  },
};
</script>

<style scoped>
@import "../../assets/style/Examine_CSS.css";
</style>
